body {
    font-family: Arial, Helvetica, sans-serif;
}

.App {
    background-color: white;
    color: #282c34;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-user {
    text-align: right;
    justify-content: right;
    margin: 1vmin;
    margin-right: 0vmin;
    font-size: 16px;
    display: inline-block;
}

.isSelected {
    background-color: dimgrey;
}

.media-item {
    cursor: 'pointer';
    text-align: 'left';
    font-size: 12;
    padding-right: 0px;
    padding-left: 0px;
    margin: 0px;
}

.App-link {
    color: #61dafb;
}

.tokens {
    margin-top: 30px;
    font-size: 16px;
    word-break: break-all;
}

.no-pad {
    padding-left: 0;
    padding-right: 0;
}

.no-alarm {
    text-align: center;
}

.app-container {
    width: 100%;
}

.content-container {
    display: flex;
}

.no-media-container {
    width: 65%;
    min-width: 695px;
}

.media-section {
    width: 35%;
}

.media-section > .row {
    margin: auto;
}

/* Media query for smaller screens */
@media (max-width: 1200px) {
    .content-container {
        flex-direction: column; /* Change to a single column layout */
    }

    .no-media-container {
        flex: 100%;
    }

    .media-section {
        flex: 100%; /* Take up the full width */
        padding: 0;
        margin-bottom: 50px;
        min-width: 695px;
    }
}

.media-item {
    background-color: #f3f3f3;
    cursor: pointer;
    border-bottom: 1px solid #c0c0c0;
    font-size: 10pt;
    display: flex;
    min-width: 298px;
}

.media-item-selected {
    background-color: #fefedf;
    display: flex;
    min-width: 298px;
}

.media-item:hover {
    background-color: #fffdaa;
}

.media-item-icon {
    justify-content: flex-start;
    min-width: 35px;
}

.media-item-checkbox {
    justify-content: center;
    min-width: 35px;
}

.media-item-details {
    min-width: 200px;
}

.media-detection-container {
    color: #0164aa;
    text-align: right;
    padding: 5px;
    min-width: 35px;
}

.media-player {
    border: 1px solid black;
    min-width: 300px;
}

.no-media-always {
    text-align: right;
}

.col-header {
    text-align: left;
    color: #e3e3e3;
    padding: 3px;
    font-weight: bold;
    font-size: 10pt;
    background-color: #484848;
}

.no-media-placeholder {
    width: 100%;
    height: 100%;
    background-color: #ddd;
}

.no-media-placeholder-img {
    margin: 0 !important;
    padding: 0 !important;
}

.media-loading-container {
    width: 640px;
}

.media-loading-placeholder {
    width: 320px;
    height: 240px;
    background-color: #ddd;
}

.media-loading-placeholder-img {
    margin: 0 !important;
    padding: 0 !important;
}

.media-clip-list {
    border: 1px solid;
    min-width: 300px;
}

.media-clip-device {
    color: #880000;
}

.media-clip-time {
    font-size: 8pt;
    font-weight: bold;
    margin-top: 3px;
}

.site-alarm-info-container {
    border: 1px solid;
}

.detection-confidence {
    margin: -6px 1px -3px 0px;
    color: black;
    font-size: 0.7em;
}

/*.site-alarm-header {
  color: #e3e3e3;
  font-size: 10pt;
  font-weight: bold;
  padding: 3px;
}*/

.site-alarm-details {
    width: 400px;
    font-size: 10pt;
    padding: 3px;
}

.site-alarm-cell {
    font-size: 10pt;
    padding: 0px 4px 0px 4px;
}

.top-site-cell {
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
}

.bottom-site-cell {
    border-top: 0px;
    border-bottom: 1px solid #c0c0c0;
}

.left-site-cell {
    border-left: 1px solid #c0c0c0;
    border-right: 0px;
    width: 120px;
}

.right-site-cell {
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    width: 300px;
}

.site-alarm-column {
    margin: 0;
    padding: 0;
}

.app-message,
.app-error {
    font-size: 14pt;
    font-weight: 500;
    text-align: center;
    margin: auto;
    width: 50%;
    padding: 10px;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;
}

.download-button-container {
    display: flex;
    justify-content: right;
    margin: 4px 0 4px 0;
    padding: 0;
}

.legal-toast {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
}
